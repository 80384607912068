<template>
  <div id="app">
    <AppchargeCheckoutInit
      :checkoutToken="checkoutToken"
      :environment="environment"
    />
    <div class="header">
      <div style="display: flex; justify-content: flex-start">
        <img class="profile" src="./assets/profile.png" alt="profile" />
      </div>
      <img class="store-logo" src="./assets/logo_new.png" alt="store logo" />
      <div>
        <button @click="onGetPricePointsClicked">Get price points</button>
      </div>
    </div>
    <div class="wrapper">
      <div class="bundle-container">
        <img
          data-testid="offer-1"
          class="bundle"
          src="./assets/bundle.png"
          alt="bundle"
          @click="createOrder"
        />
        <div class="price">{{ price }}</div>
      </div>
      <div class="bundle-container">
        <img
          data-testid="offer-2"
          class="bundle"
          src="./assets/bundle.png"
          alt="bundle"
          @click="createOrder"
        />
        <div class="price">{{ price }}</div>
      </div>
      <div class="bundle-container">
        <img
          data-testid="offer-3"
          class="bundle"
          src="./assets/bundle.png"
          alt="bundle"
          @click="createOrder"
        />
        <div class="price">{{ price }}</div>
      </div>
    </div>
    <h2 v-if="isFetchingOrder">fetching</h2>
    <div>
      <AppchargeCheckout
        v-if="loadBundle"
        :sessionToken="sessionToken"
        :checkoutToken="checkoutToken"
        :checkoutUrl="checkoutUrl"
        :on-initial-load="handleInitialLoad"
        :on-close="closeCheckout"
      />
    </div>
    <JsonEditorVue
      v-model="mockData"
      mode="json"
      :navigationBar="false"
      :mainMenuBar="false"
      :style="{
        maxHeight: '50vh',
        textAlign: 'left',
      }"
    />
  </div>
</template>

<script>
import {
  getPricePoints,
  AppchargeCheckout,
  AppchargeCheckoutInit,
} from "appcharge-checkout-vuejs-sdk";
import axios from "axios";
import JsonEditorVue from "json-editor-vue";

const mockData = {
  customer: {
    id: "John Doe",
    email: "test@appcharge.com",
  },
  priceDetails: {
    price: 1550,
    currency: "usd",
  },
  offer: {
    name: "Crazy Pack",
    sku: "CrazyPack",
    assetUrl:
      "https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/fire-fire-logo-fire-fire-icon-fire-sign-fire-symbol-transparent-background-ai-generative-free-png.png",
    description: "Crazy Pack Bundle",
  },
  items: [
    {
      name: "Coins",
      assetUrl: "https://media-dev.appcharge.com/media/product-3.png",
      sku: "coins_xoxoxo",
      quantity: 6580,
    },
    {
      name: "Tokens",
      assetUrl: "https://media-dev.appcharge.com/media/bills.png",
      sku: "coins_xoxoxo",
      quantity: 150,
    },
    {
      name: "Boosters",
      assetUrl: "https://media-dev.appcharge.com/media/booster.png",
      sku: "coins_xoxoxo",
      quantity: 3,
    },
  ],
  sessionMetadata: {},
};
const env = process.env.VUE_APP_ENV || "dev";
const lambdaPrefix = process.env.VUE_APP_LAMBDA_PREFIX || "rryhv338ih";
const publisherToken =
  process.env.VUE_APP_PUBLISHER_TOKEN ||
  "81aec2b346c7da6e7f400116f74c1d835915651246afd35de69424ba50a26136";
const checkoutToken =
  process.env.VUE_APP_CHECKOUT_TOKEN ||
  "a6a4827448708d659cebf6e4b54a00e9c8c0fddd3924fb9be3412653a0d08225";

export default {
  name: "App",
  data: () => {
    return {
      isFetchingOrder: false,
      loadBundle: false,
      sessionToken: "",
      checkoutUrl: "",
      mockData,
      price: `$${(mockData.priceDetails.price / 100).toFixed(2)}`,
      environment: env === "production" ? "prod" : env,
      publisherToken,
      checkoutToken,
    };
  },
  components: {
    JsonEditorVue,
    AppchargeCheckout,
    AppchargeCheckoutInit,
  },
  methods: {
    handleInitialLoad() {
      this.isAppchargeCheckoutLoaded = true;
    },
    createOrder() {
      this.isFetchingOrder = true;
      axios
        .post(
          `https://${lambdaPrefix}.execute-api.us-east-1.amazonaws.com/${env}/createSession`,
          this.mockData,
          {
            headers: {
              "x-publisher-token": publisherToken,
            },
          }
        )
        .then((response) => {
          this.sessionToken = response.data.checkoutSessionToken;
          this.checkoutUrl = response.data.url;
          this.loadBundle = true;
          this.isFetchingOrder = false;
        });
    },
    closeCheckout() {
      this.loadBundle = false;
    },
    async onGetPricePointsClicked() {
      try {
        const pricePoints = await getPricePoints(env);
        const relevantPrice = pricePoints.pricingPoints.find(
          (p) => p.basePriceInUSD == this.mockData.priceDetails.price
        ).formattedPrice;
        this.price = relevantPrice;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    mockData: function (val) {
      this.price = `$${(val.priceDetails.price / 100).toFixed(2)}`;
      this.mockData = val;
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("./assets/bg_1.jpg");
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #2c3e50;
  padding-top: 60px;
}

.store-logo {
  max-width: 50%;
}

.profile {
  height: 60px;
}

.header {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > div {
  width: 100px;
}

.price {
  height: 40px;
  width: 80px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0px 0px 6px #000;
}

.wrapper {
  margin-bottom: 60px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.bundle-container {
  position: relative;
}

.bundle {
  max-width: 300px;
  cursor: pointer;
}

button {
  background-color: #6200a3;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid yellow;
  padding: 8px;
  color: white;
  font-weight: 600;
}
</style>
